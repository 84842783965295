import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, BookOpen, Users, Target, Award, Calendar } from 'lucide-react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useGeoLocation } from './hooks/useGeoLocation';

const EbookPage = () => {
  const { downloadUrl } = useGeoLocation();

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Book",
    "name": "Family Business Playbook",
    "author": {
      "@type": "Person",
      "name": "Harish Hegde",
      "description": "Business consultant with over 40 years of experience",
      "image": "https://kabett.com/images/ebook/author-image-harish-hegde-family-business.webp"
    },
    "description": "A comprehensive guide to running and growing family-owned businesses, covering succession planning, professional management, and sustainable growth strategies.",
    "genre": "Business & Economics",
    "publisher": {
      "@type": "Organization",
      "name": "Kabett"
    },
    "offers": {
      "@type": "Offer",
      "availability": "https://schema.org/InStock",
      "price": "0",
      "priceCurrency": "USD"
    }
  };

  return (
    <>
      <Helmet>
        <title>Family Business Playbook | Free Ebook by Harish Hegde | Kabett</title>
        <meta name="description" content="Download your free guide to running a successful family business. Learn proven strategies from Harish Hegde's four decades of experience in business consulting." />
        <meta name="keywords" content="family business guide, business management, succession planning, Harish Hegde, business growth, family enterprise, business consulting" />
        
        {/* Open Graph tags */}
        <meta property="og:title" content="Family Business Playbook | Free Ebook by Harish Hegde" />
        <meta property="og:description" content="Download your free guide to running a successful family business. Learn proven strategies from Harish Hegde's four decades of experience." />
        <meta property="og:image" content="https://kabett.com/images/ebook/ebook-cover-family-business-playbook.webp" />
        <meta property="og:url" content="https://kabett.com/ebook" />
        <meta property="og:type" content="book" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Family Business Playbook | Free Ebook" />
        <meta name="twitter:description" content="Download your free guide to running a successful family business. Learn proven strategies from Harish Hegde." />
        <meta name="twitter:image" content="https://kabett.com/images/ebook/ebook-cover-family-business-playbook.webp" />
        
        {/* Additional SEO meta tags */}
        <meta name="author" content="Harish Hegde" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://kabett.com/ebook" />
        
        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      {/* Consultation Banner */}
      <div className="bg-gradient-to-r from-[#BAB37A]/10 to-[#BAB37A]/20 p-6 rounded-lg mb-12">
        <div className="flex flex-col md:flex-row items-center gap-4 justify-between">
          <div className="flex items-center gap-4">
            <div className="w-12 h-12 rounded-full overflow-hidden flex-shrink-0">
              <img 
                src="images/ebook/author-image-harish-hegde-family-business.webp" 
                alt="Harish Hegde" 
                className="w-full h-full object-cover"
              />
            </div>
            <p className="text-gray-600">
              <span className="font-semibold">Need personalized guidance?</span> Book a free 45-minute consultation with the author
            </p>
          </div>
          <Link 
            to="/consultation"
            className="inline-flex items-center space-x-2 bg-[#BAB37A] text-white px-4 py-2 rounded-full text-sm font-semibold hover:bg-[#9A9463] transition-colors"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          >
            <Calendar size={16} />
            <span>Book Free Session</span>
          </Link>
        </div>
      </div>

      {/* Hero Section */}
      <section className="flex flex-col md:flex-row items-center justify-between mb-24">
        <div className="md:w-1/2 mb-12 md:mb-0 md:pr-12">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-serif font-bold mb-8 leading-tight">
            Family Business Playbook
          </h1>
          <p className="text-xl mb-8 text-gray-600">
            Transform your family business with proven strategies from Harish Hegde's four decades of experience in business consulting and entrepreneurship.
          </p>
          <motion.a
            href={downloadUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-[#BAB37A] text-white px-8 py-4 rounded-full font-semibold flex items-center space-x-2 hover:bg-[#9A9463] transition-colors inline-flex"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <span>Download Now</span>
            <ArrowRight size={20} />
          </motion.a>
        </div>
        <div className="md:w-1/2">
          <div className="relative w-full flex justify-center">
            <div className="w-64 relative">
              <img 
                src="images/ebook/ebook-cover-family-business-playbook.webp" 
                alt="Family Business Playbook Cover" 
                className="w-full h-[400px] rounded-lg shadow-2xl"
                style={{ objectFit: 'cover' }}
              />
              <div className="absolute -bottom-4 -right-4 bg-white p-4 rounded-lg shadow-lg">
                <p className="text-2xl font-bold text-[#BAB37A]">40+ Years</p>
                <p className="text-sm text-gray-600">of Experience</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Key Benefits Section */}
      <section className="mb-24">
        <h2 className="text-3xl font-serif font-bold mb-12 text-center">What You'll Learn</h2>
        <div className="grid md:grid-cols-3 gap-8">
          <div className="p-6 rounded-lg bg-gray-50">
            <div className="w-12 h-12 bg-[#BAB37A] rounded-full flex items-center justify-center mb-4">
              <Users className="text-white" size={24} />
            </div>
            <h3 className="text-xl font-bold mb-2">Family Dynamics</h3>
            <p className="text-gray-600">Master the art of managing complex family relationships while running a successful business.</p>
          </div>
          <div className="p-6 rounded-lg bg-gray-50">
            <div className="w-12 h-12 bg-[#BAB37A] rounded-full flex items-center justify-center mb-4">
              <Target className="text-white" size={24} />
            </div>
            <h3 className="text-xl font-bold mb-2">Succession Planning</h3>
            <p className="text-gray-600">Learn how to create and implement effective succession plans for long-term sustainability.</p>
          </div>
          <div className="p-6 rounded-lg bg-gray-50">
            <div className="w-12 h-12 bg-[#BAB37A] rounded-full flex items-center justify-center mb-4">
              <Award className="text-white" size={24} />
            </div>
            <h3 className="text-xl font-bold mb-2">Professional Growth</h3>
            <p className="text-gray-600">Transform your family-run business into a professionally managed enterprise.</p>
          </div>
        </div>
      </section>

      {/* Author Section */}
      <section className="mb-24 bg-gray-50 p-12 rounded-lg">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/3 mb-8 md:mb-0">
            <img 
              src="images/ebook/author-image-harish-hegde-family-business.webp" 
              alt="Harish Hegde" 
              className="w-48 h-48 rounded-full object-cover mx-auto"
            />
          </div>
          <div className="md:w-2/3 md:pl-12">
            <h2 className="text-3xl font-serif font-bold mb-4">About the Author</h2>
            <p className="text-gray-600 mb-4">
              Harish Hegde brings four decades of experience in India's photographic retail industry and 8 years of dedicated consulting for family-owned businesses. His practical insights and real-world experience have helped numerous family businesses thrive in today's competitive landscape.
            </p>
            <div className="flex flex-wrap gap-4">
              <div className="bg-white p-4 rounded-lg shadow-sm">
                <p className="text-2xl font-bold text-[#BAB37A]">40+</p>
                <p className="text-sm text-gray-600">Years Experience</p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow-sm">
                <p className="text-2xl font-bold text-[#BAB37A]">100+</p>
                <p className="text-sm text-gray-600">Businesses Helped</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="text-center bg-[#BAB37A] text-white p-12 rounded-lg">
        <h2 className="text-3xl font-serif font-bold mb-4">Ready to Transform Your Family Business?</h2>
        <p className="text-xl mb-8">Get your copy of "Family Business Playbook" today</p>
        <motion.a
          href={downloadUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-white text-[#BAB37A] px-8 py-4 rounded-full font-semibold inline-flex items-center space-x-2 hover:bg-gray-100 transition-colors"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <BookOpen size={20} />
          <span>Download Now</span>
        </motion.a>
      </section>
    </>
  );
};

export default EbookPage; 