import { useState, useEffect } from 'react';

const INDIA_FORM_URL = "https://docs.google.com/forms/d/e/1FAIpQLSeaNy4_Uf0clxVuSz7AoKc-mrQ3RCN0RXfbiyu4BhifMAHepQ/viewform?usp=dialog";
const GLOBAL_FORM_URL = "https://docs.google.com/forms/d/e/1FAIpQLSfosIl7yKFIRCbHojvZqoQN0872wmknoT4g-ozZbyK_t3dWzA/viewform?usp=dialog";

export const useGeoLocation = () => {
  const [downloadUrl, setDownloadUrl] = useState(GLOBAL_FORM_URL); // Default to global URL
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const detectLocation = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        
        // Check if user is from India
        if (data.country === 'IN') {
          setDownloadUrl(INDIA_FORM_URL);
        } else {
          setDownloadUrl(GLOBAL_FORM_URL);
        }
        setLoading(false);
      } catch (err) {
        console.error('Error detecting location:', err);
        setError(err);
        setLoading(false);
        // Fallback to global URL in case of error
        setDownloadUrl(GLOBAL_FORM_URL);
      }
    };

    detectLocation();
  }, []);

  return { downloadUrl, loading, error };
}; 