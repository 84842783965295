import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { Target, Users, BarChart3, Calendar, ArrowRight, BookOpen } from 'lucide-react';

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="bg-gray-50 p-6 rounded-lg">
    <div className="w-12 h-12 bg-[#BAB37A] rounded-full flex items-center justify-center mb-4">
      <Icon className="text-white" size={24} />
    </div>
    <h3 className="text-xl font-bold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const ProcessStep = ({ number, title, description }) => (
  <div className="flex items-start gap-4 max-w-2xl mx-auto">
    <div className="flex-shrink-0 w-8 h-8 bg-[#BAB37A] text-white rounded-full flex items-center justify-center font-bold">
      {number}
    </div>
    <div>
      <h3 className="font-bold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const BusinessConsultationPage = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": "Business Consultation Services by Harish Hegde",
    "provider": {
      "@type": "Person",
      "name": "Harish Hegde",
      "description": "Business consultant with over 40 years of experience",
      "image": "https://kabett.com/images/ebook/author-image-harish-hegde-family-business.webp"
    },
    "serviceType": "Business Consultation",
    "description": "Expert business consultation services for small and family-owned businesses. Transform your business with our Small Entrepreneurship Solutions program.",
    "offers": {
      "@type": "Offer",
      "description": "Free 45-minute initial consultation session"
    }
  };

  return (
    <>
      <Helmet>
        <title>Business Consultation Services | Harish Hegde | Kabett</title>
        <meta name="description" content="Transform your business with expert consultation from Harish Hegde. 40+ years of experience in helping businesses grow. Book your free 45-minute consultation today." />
        <meta name="keywords" content="business consultation, Harish Hegde, business growth, entrepreneurship solutions, business strategy, financial optimization, team development" />
        
        {/* Open Graph tags for social sharing */}
        <meta property="og:title" content="Business Consultation Services | Harish Hegde | Kabett" />
        <meta property="og:description" content="Transform your business with expert consultation from Harish Hegde. 40+ years of experience in helping businesses grow." />
        <meta property="og:image" content="https://kabett.com/images/ebook/author-image-harish-hegde-family-business.webp" />
        <meta property="og:url" content="https://kabett.com/consultation" />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Business Consultation Services | Harish Hegde" />
        <meta name="twitter:description" content="Transform your business with expert consultation from Harish Hegde. 40+ years of experience in helping businesses grow." />
        <meta name="twitter:image" content="https://kabett.com/images/ebook/author-image-harish-hegde-family-business.webp" />
        
        {/* Additional SEO meta tags */}
        <meta name="author" content="Harish Hegde" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://kabett.com/consultation" />
        
        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      {/* Hero Section */}
      <section className="flex flex-col md:flex-row items-center justify-between mb-24">
        <div className="md:w-1/2 mb-12 md:mb-0 md:pr-12">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-serif font-bold mb-8 leading-tight">
            Transform Your Business Journey
          </h1>
          <p className="text-xl mb-8 text-gray-600">
            Expert guidance to take your business to the next level with our Small Entrepreneurship Solutions program. With over four decades of experience, we help you navigate the challenges of growth and success.
          </p>
          <motion.button
            className="bg-[#BAB37A] text-white px-8 py-4 rounded-full font-semibold flex items-center space-x-2 hover:bg-[#9A9463] transition-colors"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => document.getElementById('contact-section').scrollIntoView({ behavior: 'smooth' })}
          >
            <span>Book Free Consultation</span>
            <ArrowRight size={20} />
          </motion.button>
        </div>
        <div className="md:w-1/2">
          <div className="relative">
            <img 
              src="images/ebook/author-image-harish-hegde-family-business.webp" 
              alt="Harish Hegde" 
              className="w-full rounded-lg shadow-2xl"
            />
            <div className="absolute -bottom-4 -right-4 bg-white p-4 rounded-lg shadow-lg">
              <p className="text-2xl font-bold text-[#BAB37A]">40+ Years</p>
              <p className="text-sm text-gray-600">of Experience</p>
            </div>
          </div>
        </div>
      </section>

      {/* Pain Points Section */}
      <section className="mb-24">
        <h2 className="text-3xl font-serif font-bold mb-12 text-center">Common Business Challenges</h2>
        <div className="bg-gray-50 p-8 rounded-lg max-w-4xl mx-auto">
          <p className="text-xl mb-6 text-center">Are you facing any of these challenges in your business?</p>
          <div className="grid md:grid-cols-2 gap-6">
            <ul className="space-y-4">
              <li className="flex items-center gap-3">
                <div className="w-2 h-2 bg-[#BAB37A] rounded-full"></div>
                <span>Trouble attracting new prospects and customers</span>
              </li>
              <li className="flex items-center gap-3">
                <div className="w-2 h-2 bg-[#BAB37A] rounded-full"></div>
                <span>Declining sales without clear reasons</span>
              </li>
              <li className="flex items-center gap-3">
                <div className="w-2 h-2 bg-[#BAB37A] rounded-full"></div>
                <span>Struggling with market competitiveness</span>
              </li>
              <li className="flex items-center gap-3">
                <div className="w-2 h-2 bg-[#BAB37A] rounded-full"></div>
                <span>Difficulty in attracting skilled staff</span>
              </li>
            </ul>
            <ul className="space-y-4">
              <li className="flex items-center gap-3">
                <div className="w-2 h-2 bg-[#BAB37A] rounded-full"></div>
                <span>Challenges with tax planning and compliance</span>
              </li>
              <li className="flex items-center gap-3">
                <div className="w-2 h-2 bg-[#BAB37A] rounded-full"></div>
                <span>Overwhelmed by day-to-day operations</span>
              </li>
              <li className="flex items-center gap-3">
                <div className="w-2 h-2 bg-[#BAB37A] rounded-full"></div>
                <span>Unable to take time off from business</span>
              </li>
              <li className="flex items-center gap-3">
                <div className="w-2 h-2 bg-[#BAB37A] rounded-full"></div>
                <span>Stuck in business growth</span>
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* Solutions Section */}
      <section className="mb-24">
        <h2 className="text-3xl font-serif font-bold mb-12 text-center">How I Can Help</h2>
        <div className="grid md:grid-cols-3 gap-8">
          <FeatureCard 
            icon={Target}
            title="Strategic Planning"
            description="Develop clear business objectives and actionable plans to achieve your goals."
          />
          <FeatureCard 
            icon={Users}
            title="Team Development"
            description="Build and manage high-performing teams that drive business growth."
          />
          <FeatureCard 
            icon={BarChart3}
            title="Financial Optimization"
            description="Improve cash flow and strengthen your business model for sustainable growth."
          />
        </div>
      </section>

      {/* Process Section */}
      <section className="mb-24">
        <h2 className="text-3xl font-serif font-bold mb-12 text-center">The Consultation Process</h2>
        <div className="space-y-8">
          <ProcessStep 
            number="1"
            title="Free Initial Consultation"
            description="A 45-minute business review session to understand your current situation and challenges."
          />
          <ProcessStep 
            number="2"
            title="Custom Strategy Development"
            description="Based on your needs, we'll create a tailored plan to address your specific challenges."
          />
          <ProcessStep 
            number="3"
            title="Implementation Support"
            description="Regular guidance and support to ensure successful execution of the strategy."
          />
        </div>
      </section>

      {/* Results Section */}
      <section className="mb-24 bg-gray-50 p-12 rounded-lg">
        <h2 className="text-3xl font-serif font-bold mb-8 text-center">Results You Can Expect</h2>
        <div className="grid md:grid-cols-3 gap-8">
          <div className="text-center">
            <div className="text-4xl font-bold text-[#BAB37A] mb-2">30%</div>
            <p className="text-gray-600">Average Revenue Growth</p>
          </div>
          <div className="text-center">
            <div className="text-4xl font-bold text-[#BAB37A] mb-2">50%</div>
            <p className="text-gray-600">Improved Efficiency</p>
          </div>
          <div className="text-center">
            <div className="text-4xl font-bold text-[#BAB37A] mb-2">100%</div>
            <p className="text-gray-600">Client Satisfaction</p>
          </div>
        </div>
      </section>

      {/* Cross Promotion */}
      <section className="mb-24 bg-gradient-to-r from-[#BAB37A]/10 to-[#BAB37A]/20 p-12 rounded-lg">
        <div className="flex flex-col md:flex-row items-center gap-8">
          <div className="md:w-1/4">
            <img 
              src="images/ebook/ebook-cover-family-business-playbook.webp" 
              alt="Family Business Guide Ebook" 
              className="w-full rounded-lg shadow-xl"
            />
          </div>
          <div className="md:w-3/4">
            <h2 className="text-2xl font-serif font-bold mb-4">Free Resource: Family Business Guide</h2>
            <p className="text-gray-600 mb-6">
              Want to learn more about running a successful family business? Download our comprehensive guide that covers everything from succession planning to professional management.
            </p>
            <Link 
              to="/ebook"
              className="inline-flex items-center space-x-2 text-[#BAB37A] font-semibold hover:text-[#9A9463] transition-colors"
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
              <BookOpen size={20} />
              <span>Get Your Free Guide</span>
              <ArrowRight size={20} />
            </Link>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section id="contact-section" className="text-center bg-[#BAB37A] text-white p-12 rounded-lg">
        <h2 className="text-3xl font-serif font-bold mb-4">Ready to Transform Your Business?</h2>
        <p className="text-xl mb-8">Book your free 45-minute consultation session today</p>
        <Link 
          to="/contact"
          className="bg-white text-[#BAB37A] px-8 py-4 rounded-full font-semibold inline-flex items-center space-x-2 hover:bg-gray-100 transition-colors"
        >
          <Calendar size={20} />
          <span>Schedule Consultation</span>
        </Link>
      </section>
    </>
  );
};

export default BusinessConsultationPage;