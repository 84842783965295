import React, { useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { ArrowRight, BookOpen } from 'lucide-react';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import ContactPage from './ContactPage';
import BusinessConsultationPage from './BusinessConsultationPage';
import EbookPage from './EbookPage';
import { useGeoLocation } from './hooks/useGeoLocation';

const HomePage = () => {
  const productsRef = useRef(null);
  const { downloadUrl } = useGeoLocation();

  const scrollToProducts = () => {
    productsRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Helmet>
        <title>Kabett - Building Useful Products for Everyday Life</title>
        <meta name="description" content="Kabett creates smart, simple solutions for everyday challenges. Discover our innovative products designed to make your life easier." />
        <meta name="keywords" content="Kabett, productivity tools, health apps, wellness applications, everyday solutions" />
        <meta property="og:title" content="Kabett - Innovative Solutions for Daily Life" />
        <meta property="og:description" content="Discover Kabett's suite of products designed to enhance your daily life and productivity." />
        <meta property="og:image" content="https://kabett.com/kabett-screen.webp" />
        <meta property="og:url" content="https://kabett.com" />
      </Helmet>

      {/* Announcement Banner */}
      <div className="bg-[#BAB37A] text-white py-2 mb-12 -mt-4 rounded-lg">
        <div className="flex items-center justify-between px-6">
          <div className="flex items-center space-x-3">
            <BookOpen size={20} />
            <p className="text-sm md:text-base">
              <span className="font-semibold">New Release:</span> Download our free ebook "Family Business Playbook"
            </p>
          </div>
          <a 
            href={downloadUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-2 bg-white text-[#BAB37A] px-4 py-1 rounded-full text-sm font-semibold hover:bg-gray-100 transition-colors"
          >
            <span>Get it Free</span>
            <ArrowRight size={16} />
          </a>
        </div>
      </div>

      <section className="flex flex-col md:flex-row items-start justify-between mb-24">
        <div className="md:w-1/2 mb-12 md:mb-0 md:pr-12">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-serif font-bold mb-8 leading-tight">
            Building Useful Products for Everyday Life
          </h1>
          <p className="text-xl mb-8 max-w-2xl text-gray-600">
            Ever wished for tools that just make life easier? That's what we do at Kabett. We're all about creating smart, simple solutions for everyday challenges. From helping new moms navigate parenthood to bringing calm into your busy day, our products are designed with you in mind. Ready to discover how we can make your day a little bit better?
          </p>
          <motion.button
            className="bg-transparent text-[#BAB37A] px-6 py-3 rounded-full font-semibold flex items-center space-x-2 hover:bg-[#BAB37A] hover:text-white transition-colors border border-[#BAB37A]"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={scrollToProducts}
          >
            <span>Explore Our Products</span>
            <ArrowRight size={20} />
          </motion.button>
        </div>
        <div className="md:w-1/2">
          <div className="w-full h-0 pb-[75%] relative rounded-lg overflow-hidden">
            <img 
              src="/kabett-header.webp" 
              alt="Kabett products enhancing everyday life" 
              className="absolute top-0 left-0 w-full h-full object-cover"
            />
          </div>
        </div>
      </section>

      <section ref={productsRef} className="mb-24">
        <h2 className="text-4xl font-serif font-bold mb-8">Our Products</h2>
        <div className="grid md:grid-cols-3 gap-12">
          <div className="bg-blue-50 p-8 rounded-lg">
            <div className="flex justify-center mb-6">
              <img src="/mumma-care.png" alt="MummaCare Logo" className="w-32 h-32 rounded-full object-cover" />
            </div>
            <h3 className="text-2xl font-serif font-bold mb-4 text-center">MummaCare</h3>
            <p className="mb-6 text-center text-gray-600">Empowering mothers with comprehensive care and support throughout their journey.</p>
            <div className="text-center">
              <a href="https://mummacare.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Visit MummaCare</a>
            </div>
          </div>
          <div className="bg-pink-50 p-8 rounded-lg">
            <div className="flex justify-center mb-6">
              <img src="/pranayama.png" alt="Pranayama Extension Logo" className="w-32 h-32 rounded-full object-cover" />
            </div>
            <h3 className="text-2xl font-serif font-bold mb-4 text-center">Pranayama Chrome Extension</h3>
            <p className="mb-6 text-center text-gray-600">Enhance your browsing experience with mindful breathing exercises.</p>
            <div className="text-center">
              <a href="https://chromewebstore.google.com/detail/epjichmldhceknjpoihgjkgccmllpbeg" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Get the Extension</a>
            </div>
          </div>
          <div className="bg-yellow-50 p-8 rounded-lg">
            <div className="flex justify-center mb-6">
              <img src="/kabett-business-consultancy.webp" alt="Business Consultation Logo" className="w-32 h-32 rounded-full object-cover" />
            </div>
            <h3 className="text-2xl font-serif font-bold mb-4 text-center">Business Consultation</h3>
            <p className="mb-6 text-center text-gray-600">Expert guidance to take your business to the next level with our Small Entrepreneurship Solutions.</p>
            <div className="text-center">
              <Link to="/consultation" className="text-blue-600 hover:underline">Learn More</Link>
            </div>
          </div>
          <div className="bg-green-50 p-8 rounded-lg">
            <div className="flex justify-center mb-6">
              <div className="w-32 h-32 rounded-full overflow-hidden">
                <img 
                  src="images/ebook/ebook-cover-family-business-playbook.webp" 
                  alt="Family Business Ebook" 
                  className="w-full h-full object-cover"
                  style={{ objectPosition: 'center top' }}
                />
              </div>
            </div>
            <h3 className="text-2xl font-serif font-bold mb-4 text-center">Family Business Guide</h3>
            <p className="mb-6 text-center text-gray-600">Master the art of running a successful family business with our comprehensive guide.</p>
            <div className="text-center">
              <a 
                href={downloadUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                Get the Ebook
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const App = () => {
  return (
    <Router>
      <div className="min-h-screen bg-white font-sans flex flex-col">
        <Helmet>
          <link href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Merriweather:wght@400;700&display=swap" rel="stylesheet" />
        </Helmet>
        <header className="container mx-auto px-8 py-6">
          <nav className="flex flex-wrap justify-between items-center">
            <Link to="/" className="flex items-center space-x-3">
              <img src="/kabett401.webp" alt="Kabett Logo" className="w-12 h-12" />
              <div className="text-3xl font-bold" style={{ fontFamily: "'Playfair Display', serif", letterSpacing: '1px' }}>Kabett</div>
            </Link>
            <ul className="flex space-x-8 mt-4 md:mt-0">
              <li>
                <Link 
                  to="/consultation"
                  className="text-gray-600 hover:text-gray-900 bg-transparent border-none cursor-pointer"
                >
                  Consultation
                </Link>
              </li>
              <li>
                <Link 
                  to="/ebook"
                  className="text-gray-600 hover:text-gray-900 bg-transparent border-none cursor-pointer"
                >
                  Ebook
                </Link>
              </li>
              <li>
                <Link 
                  to="/privacy-policy"
                  className="text-gray-600 hover:text-gray-900 bg-transparent border-none cursor-pointer"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link 
                  to="/contact"
                  className="text-gray-600 hover:text-gray-900 bg-transparent border-none cursor-pointer"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
        </header>

        <main className="container mx-auto px-8 py-4 flex-grow">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/consultation" element={<BusinessConsultationPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/ebook" element={<EbookPage />} />
          </Routes>
        </main>

        <footer className="bg-[#F3F1E4] py-8 mt-12">
          <div className="container mx-auto px-8">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <p className="text-[#BAB37A] font-semibold mb-4 md:mb-0">&copy; 2024 Kabett. All rights reserved.</p>
              <div className="flex space-x-4">
                <Link to="/consultation" className="text-[#BAB37A] hover:text-[#9A9463]">Business Consultation</Link>
                <Link to="/ebook" className="text-[#BAB37A] hover:text-[#9A9463]">Ebook</Link>
                <Link to="/privacy-policy" className="text-[#BAB37A] hover:text-[#9A9463]">Privacy Policy</Link>
                <Link to="/contact" className="text-[#BAB37A] hover:text-[#9A9463]">Contact</Link>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
};

export default App;